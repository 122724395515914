import logo from './logo.svg';
import './App.css';
import Countdown from 'react-countdown';
import { confettiCannon, sideConfetti } from './confetti.js';

function App() {

  sideConfetti()

  return (
    <div className="App">
      <header className="App-header">
        <Countdown date={new Date(2021,12,-14, 14,0,0)}>
          <Completionist />
        </Countdown>
      </header>
    </div>
  );
}

// Random component
const Completionist = () => <iframe width="560" height="315" src="https://www.youtube.com/embed/83Q8EtmZtAo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

export default App;


